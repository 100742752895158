:global {
  .modal {
    > * {
      opacity: 0;
    }
  }

  .modal-overlay {
    transition: opacity 200ms ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }

  .modal-content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0;
    outline: none;
    padding: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    max-width: 358px;
    transition: transform 200ms ease-in-out;
    transform: translate(0, -100%);
  }

  .ReactModal__Overlay {
    &--after-open {
      opacity: 1;

      .modal-content {
        transform: translate(0, 0);
      }
    }

    &--before-close {
      opacity: 0;

      .modal-content {
        transform: translate(0, -100%);
      }
    }
  }

  body.modal-open {
    overflow: hidden;
  }
}
