//
// Icon Font: icons
//

@font-face {
  font-family: "icons";
  src: url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.eot");
  src: url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.eot?#iefix") format("embedded-opentype"),
       url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.woff2") format("woff2"),
       url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.woff") format("woff"),
       url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.ttf") format("truetype"),
       url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icons";
    src: url("../assets/fonts/icons_9b913a69b3e096f14ffc4fdb2b718fbd.svg#icons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-add-user:before,
.icon-calendar:before,
.icon-check:before,
.icon-chevron-down:before,
.icon-chevron-up:before,
.icon-circle-exclam:before,
.icon-circle-minus:before,
.icon-circle-plus:before,
.icon-circle-x:before,
.icon-contacts:before,
.icon-filter:before,
.icon-gear:before,
.icon-gift:before,
.icon-globe:before,
.icon-grip-lines:before,
.icon-list:before,
.icon-lock:before,
.icon-mobile:before,
.icon-money:before,
.icon-pencil:before,
.icon-phone:before,
.icon-phone-home:before,
.icon-phone-mobile:before,
.icon-phone-office:before,
.icon-plus:before,
.icon-print:before,
.icon-search:before,
.icon-share:before,
.icon-sort:before,
.icon-trash:before,
.icon-tribar:before,
.icon-user:before,
.icon-users:before,
.icon-x:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-add-user:before { content: "\f100"; }
.icon-calendar:before { content: "\f101"; }
.icon-check:before { content: "\f102"; }
.icon-chevron-down:before { content: "\f103"; }
.icon-chevron-up:before { content: "\f104"; }
.icon-circle-exclam:before { content: "\f11a"; }
.icon-circle-minus:before { content: "\f118"; }
.icon-circle-plus:before { content: "\f105"; }
.icon-circle-x:before { content: "\f116"; }
.icon-contacts:before { content: "\f106"; }
.icon-filter:before { content: "\f11c"; }
.icon-gear:before { content: "\f107"; }
.icon-gift:before { content: "\f11e"; }
.icon-globe:before { content: "\f11d"; }
.icon-grip-lines:before { content: "\f11b"; }
.icon-list:before { content: "\f11f"; }
.icon-lock:before { content: "\f108"; }
.icon-mobile:before { content: "\f120"; }
.icon-money:before { content: "\f109"; }
.icon-pencil:before { content: "\f10a"; }
.icon-phone:before { content: "\f10b"; }
.icon-phone-home:before { content: "\f10c"; }
.icon-phone-mobile:before { content: "\f10d"; }
.icon-phone-office:before { content: "\f10e"; }
.icon-plus:before { content: "\f10f"; }
.icon-print:before { content: "\f121"; }
.icon-search:before { content: "\f110"; }
.icon-share:before { content: "\f111"; }
.icon-sort:before { content: "\f112"; }
.icon-trash:before { content: "\f113"; }
.icon-tribar:before { content: "\f119"; }
.icon-user:before { content: "\f114"; }
.icon-users:before { content: "\f122"; }
.icon-x:before { content: "\f115"; }

$font-icons-add-user: "\f100";
$font-icons-calendar: "\f101";
$font-icons-check: "\f102";
$font-icons-chevron-down: "\f103";
$font-icons-chevron-up: "\f104";
$font-icons-circle-exclam: "\f11a";
$font-icons-circle-minus: "\f118";
$font-icons-circle-plus: "\f105";
$font-icons-circle-x: "\f116";
$font-icons-contacts: "\f106";
$font-icons-filter: "\f11c";
$font-icons-gear: "\f107";
$font-icons-gift: "\f11e";
$font-icons-globe: "\f11d";
$font-icons-grip-lines: "\f11b";
$font-icons-list: "\f11f";
$font-icons-lock: "\f108";
$font-icons-mobile: "\f120";
$font-icons-money: "\f109";
$font-icons-pencil: "\f10a";
$font-icons-phone: "\f10b";
$font-icons-phone-home: "\f10c";
$font-icons-phone-mobile: "\f10d";
$font-icons-phone-office: "\f10e";
$font-icons-plus: "\f10f";
$font-icons-print: "\f121";
$font-icons-search: "\f110";
$font-icons-share: "\f111";
$font-icons-sort: "\f112";
$font-icons-trash: "\f113";
$font-icons-tribar: "\f119";
$font-icons-user: "\f114";
$font-icons-users: "\f122";
$font-icons-x: "\f115";
