// Custom Colors
$cyan: #33e0ff;
$humility: #777;

$close-button-color:             #c4c4c4;

$brand-primary: #e9573f;
$brand-secondary: #bdbdbd;
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5bc0de;

$link-color: #245da0;

$body-bg-login: #f5f5f5;
$body-bg-signup: $body-bg-login;

$text-color: #333;

$font-size-base: 14px;
$font-family-sans-serif: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-base:        $font-family-sans-serif;


// Navs
$navbar-height: 52px;
$navbar-inverse-color: #fff;
$navbar-inverse-bg: #25263d;
$navbar-inverse-brand-color: #fff;
$navbar-inverse-link-active-bg: transparent;

// Sign Up
$signup-right-col-bg: #25273d;

$screen-xs:                  480px !default;
$screen-xs-max:              767px !default;
$screen-sm:                  768px !default;
$screen-sm-max:              991px !default;
$screen-md:                  992px !default;
$screen-md-max:              1199px !default;
$screen-lg:                  1200px !default;

// Z-Index
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;

// Modals
$modal-title-padding:         25px 25px;
$modal-inner-padding:         0 25px 25px;

$modal-lg:                    358px;
$modal-md:                    358px;
$modal-sm:                    300px;

// Buttons
$btn-border-radius-base:         0;
$btn-border-radius-large:        0;
$btn-border-radius-small:        0;

@mixin icon {
  &::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'icons';
    font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
  }
}

@mixin conversation-dropdown-menu {
  $menu-item-color: #434a54;

  > li > a {
    color: $menu-item-color;
    font-size: 15px;
    letter-spacing: .44px;
    line-height: 2.2666666667;
    padding: 3px 42px 3px 24px;
    pointer-events: auto;
    position: relative;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    @include icon;
    background-color: transparent;
    color: inherit;
    outline: 0;
    text-decoration: none;

    &::before {
      color: #e9573f;
      content: '\f105';
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      right: 20px;
      top: 0;
    }
  }

  > li > a:hover,
  > li > a:focus,
  > .active > a:hover,
  > .active > a:focus {
    background-color: #f5f5f5;
  }
}

@mixin clearfix {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin button-variant($color, $background, $border) {
  background-color: $background;
  border-color: $border;
  color: $color;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
    color: $color;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    background-color: $color;
    color: $background;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  border-radius: $border-radius;
  font-size: $font-size;
  line-height: $line-height;
  padding: $padding-vertical $padding-horizontal;
}

@mixin search-input {
  $search-input-bg:                     #fff;
  $search-input-border-color:           #ccc;

  background-color: $search-input-bg;
  border: 1px solid $search-input-border-color;
  border-radius: 17px;
  display: block;
  height: 34px;
  padding: 6px 12px 6px 36px;
  width: 100%;

  &,
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}
